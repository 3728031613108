/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

html{
    scroll-behavior: initial !important;
}

*{
    text-decoration: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

a,a:hover{
    text-decoration: none;
    color: #ffffff;
}

/* Common colors */
:root{
    --color1: rgb(9, 14, 42);
    --color1-900: rgb(9 14 42 / 90%);
    --color1-800: rgb(9 14 42 / 80%);
    --color1-700: rgb(9 14 42 / 70%);
    --color1-600: rgb(9 14 42 / 60%);
    --color1-500: rgb(9 14 42 / 50%);
    --color1-400: rgb(9 14 42 / 40%);
    --color1-300: rgb(9 14 42 / 30%);
    --color1-200: rgb(9 14 42 / 20%);
    --color1-100: rgb(9 14 42 / 10%);

    --color2: rgb(26, 86, 133);
    --color2-900: rgb(26 86 133 / 90%);
    --color2-800: rgb(26 86 133 / 80%);
    --color2-700: rgb(26 86 133 / 70%);
    --color2-600: rgb(26 86 133 / 60%);
    --color2-500: rgb(26 86 133 / 50%);
    --color2-400: rgb(26 86 133 / 40%);
    --color2-300: rgb(26 86 133 / 30%);
    --color2-200: rgb(26 86 133 / 20%);
    --color2-100: rgb(26 86 133 / 10%);

    --color3: rgb(34, 197, 94);
    --color3-900: rgb(34 197 94 / 90%);
    --color3-800: rgb(34 197 94 / 80%);
    --color3-700: rgb(34 197 94 / 70%);
    --color3-600: rgb(34 197 94 / 60%);
    --color3-500: rgb(34 197 94 / 50%);
    --color3-400: rgb(34 197 94 / 40%);
    --color3-300: rgb(34 197 94 / 30%);
    --color3-200: rgb(34 197 94 / 20%);
    --color3-100: rgb(34 197 94 / 10%);

    --color4: rgb(250, 130, 106);
    --color4-900: rgb(250 130 106 / 90%);
    --color4-800: rgb(250 130 106 / 80%);
    --color4-700: rgb(250 130 106 / 70%);
    --color4-600: rgb(250 130 106 / 60%);
    --color4-500: rgb(250 130 106 / 50%);
    --color4-400: rgb(250 130 106 / 40%);
    --color4-300: rgb(250 130 106 / 30%);
    --color4-200: rgb(250 130 106 / 20%);
    --color4-100: rgb(250 130 106 / 10%);

    --color5: rgb(24, 28, 58);
    --color5-900: rgb(24 28 58 / 90%);

    --color6: rgb(240, 101, 20);
    --color6-900: rgb(240 101 20 / 90%);
    --color6-800: rgb(240 101 20 / 80%);
    --color6-700: rgb(240 101 20 / 70%);
    --color6-600: rgb(240 101 20 / 60%);
    --color6-500: rgb(240 101 20 / 50%);
    --color6-400: rgb(240 101 20 / 40%);
    --color6-300: rgb(240 101 20 / 30%);
    --color6-200: rgb(240 101 20 / 20%);
    --color6-100: rgb(240 101 20 / 10%);

    --color7: rgb(229, 62, 84);
    --color7-900: rgb(229 62 84 / 90%);
    --color7-800: rgb(229 62 84 / 80%);
    --color7-700: rgb(229 62 84 / 70%);
    --color7-600: rgb(229 62 84 / 60%);
    --color7-500: rgb(229 62 84 / 50%);
    --color7-400: rgb(229 62 84 / 40%);
    --color7-300: rgb(229 62 84 / 30%);
    --color7-200: rgb(229 62 84 / 20%);
    --color7-100: rgb(229 62 84 / 10%);
}


/* --- Website Fonts --- */
@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Thin.ttf");
    font-weight: 100;

}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url("../fonts/Roboto-Black.ttf");
    font-weight: 900;
}

/* --- Body tag Css --- */
body{
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.2px;
    color: white;
    background-color: var(--color1);
}

/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--color1);
    background-image: -webkit-linear-gradient(135deg, var(--color1), var(--color2));
    background-image: -moz-linear-gradient(135deg, var(--color1), var(--color2));
    background-image: -o-linear-gradient(135deg, var(--color1), var(--color2));
    background-image: linear-gradient(-45deg, var(--color1), var(--color2));
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1200;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    background-position: center;
}

.web-preloader span.web-inner {
    width: 130px;
    height: 130px;
    line-height: 150px;
    margin: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.web-preloader span.web-inner img {
    width: 100px;
    height: 100px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    position: relative;
    padding: 0px;
}

.web-preloader span.web-inner .sub-inner{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    border: 3px solid rgb(0 0 0 / 20%);
    border-left-color: var(--color2);
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    transition: all 0.3s linear;
    -webkit-animation: sub-inner-rotate 1s linear infinite;
       -moz-animation: sub-inner-rotate 1s linear infinite;
         -o-animation: sub-inner-rotate 1s linear infinite;
            animation: sub-inner-rotate 1s linear infinite;
}

@-webkit-keyframes sub-inner-rotate {
	from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes sub-inner-rotate {
	from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes sub-inner-rotate {
	from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes sub-inner-rotate {
	from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    /* transform: translateY(50px); */
    -webkit-transition: all 0.3s 0.5s ease-in-out;
    -o-transition: all 0.3s 0.5s ease-in-out;
    -moz-transition: all 0.3s 0.5s ease-in-out;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 1s ease-in-out;
    -o-transition: all 0.3s 1s ease-in-out;
    -moz-transition: all 0.3s 1s ease-in-out;
    transition: all 0.3s 1s ease-in-out;
}


/* --- Sub Loader --- */
.sub-loader{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: -webkit-calc(100%);
    height: -moz-calc(100%);
    height: calc(100%);
    z-index: 1199;
    background-color: var(--color1-800);
    opacity: 1;
    visibility: visible;
}

.sub-loader.hide{
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0.3s ease, visibility 0.3s 0.3s ease;
    -o-transition: opacity 0.3s 0.3s ease, visibility 0.3s 0.3s ease;
    -moz-transition: opacity 0.3s 0.3s ease, visibility 0.3s 0.3s ease;
    transition: opacity 0.3s 0.3s ease, visibility 0.3s 0.3s ease;
}

.sub-loader .sub-inner{
    width: 180px;
    height: 180px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 180px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 180px;
            flex: 0 0 180px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    border: 3px solid var(--color2-300);
    border-left-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    transition: all 0.3s linear;
    -webkit-animation: sub-inner-rotate 0.5s linear infinite;
       -moz-animation: sub-inner-rotate 0.5s linear infinite;
         -o-animation: sub-inner-rotate 0.5s linear infinite;
            animation: sub-inner-rotate 0.5s linear infinite;
}

.sub-loader .sub-inner::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/icons/wifi.png");
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: 50% 50%;
       -moz-background-size: 50%;
         -o-background-size: 50%;
            background-size: 50%;
    opacity: 0.5;
    animation: sub-inner-rotate 0.5s linear reverse infinite;
}

.sub-loader .sub-loader-text{
    margin-top: 15px;
}


/* --- main-wrapper --- */
.main-wrapper{
    min-height: -webkit-calc(100vh - 60px);
    min-height: -moz-calc(100vh - 60px);
    min-height: calc(100vh - 60px);
}


/* --- Max-width css --- */
.max-w-1920{
    width: 100%;
    max-width: 1920px;
    margin: auto;
}

/* --- Back Btn --- */
.back-btn{
    position: absolute;
    left: 15px;
    top: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--color2-500);
}

.back-btn:hover{
    background-color: var(--color2);
}

.back-btn svg{
    width: 20px;
    height: 20px;
    position: relative;
    left: -1px;
}

/* --- Breadcrumb Css --- */
.breadcrumb-outer{
    padding: 12px 0px 12px;
    z-index: 1;
    width: 100%;
    overflow: hidden;
}

.breadcrumb-bx{   
    display: -webkit-inline-box;   
    display: -webkit-inline-flex;   
    display: -moz-inline-box;   
    display: -ms-inline-flexbox;   
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-back{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.breadcrumb-bx .breadcrumb-back:hover{
    background-color: #2b262b;
}

.breadcrumb-bx .breadcrumb-back svg{
    width: 20px;
    height: 20px;
    position: relative;
    left: -1px;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 14px;
    font-weight: 300;
    margin-right: 8px;
    color: #f2f1f3;
}

.breadcrumb-bx .breadcrumb-link:hover{
    text-decoration: underline;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 300;
    opacity: 0.7;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link .fa.fa-angle-right{
    margin-left: 8px;
    color: #f2f1f3;
    position: relative;
    top: 0px;
}

/* --- Breadcrumb Style 2 --- */
.breadcrumb-outer.bctwo{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 51;
    background-color: #121114;
    border-bottom: 1px solid black;
    padding: 10px 0;
}

.breadcrumb-outer.bctwo .container-lg.cl-custom{
    padding: 0px 10px;
}

.breadcrumb-bx img{
    width: 45px;
    height: 45px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 45px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 45px;
            flex: 0 0 45px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    margin-right: 8px;
}

.breadcrumb-bx .breadcrumb-text-data{
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 36px;
       -moz-box-flex: 1;
        -ms-flex: 1 1 36px;
            flex: 1 1 36px;
}

.breadcrumb-bx .bc-lable{
    font-size: 14px;
    font-weight: 500;
    color: #f2f1f3;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

.breadcrumb-bx .bc-sub-lable{
    color: #BFBAC4;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}


/* --- Common Scrollbar Hide --- */
.commonscrollbarhide::-webkit-scrollbar{
    display: none;
}

/* --- Close Btn Css --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: white;
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    z-index: 51;
}

.close-icon:hover{
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
    background: var(--color2-500);
    color: white;
    opacity: 1;
}

/* --- Overlaydiv Css --- */
.overlaydiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #0000004d;
    opacity: 0;
    visibility: hidden;
    -webkit-transition:opacity 0.3s ease-in-out;
    -o-transition:opacity 0.3s ease-in-out;
    -moz-transition:opacity 0.3s ease-in-out;
    transition:opacity 0.3s ease-in-out;
    top: 0;
    cursor: pointer;
    margin: 0 !important;
    z-index: 1998;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.overlaydiv.active {
    visibility: visible;
    opacity: 1;
}

/* --- Common text layout Css Start --- */
.common-text-layout .ctl-tag{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: var(--color6);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.common-text-layout .ctl-tag .ctl-t-num{
    width: 20px;
    height: 20px;
    /* list-style: 20px; */
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 12px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    color: white;
    background-color: var(--color6);
    margin-right: 5px;
    line-height: 20px;
}

.common-text-layout .ctl-heading{
    color: white;
    font-size: 28px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: normal;
}

.common-text-layout .ctl-text{
    font-size: 18px;
    margin: 0;
    margin-top: 10px;
    font-weight: 400;
    color: rgb(191, 186, 196);
}

.common-text-layout .ctl-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 20px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    color: #f2f1f3;
    font-size: 17px;
    margin-top: 25px;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--color6);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-text-layout .ctl-btn:hover{
    opacity: 1;
}

.common-digital-layout video.cdl-video,
.common-digital-layout img.cdl-img{
    max-width: 90%;
    width:100%;
    display: block;
    margin: auto;
}

.common-digital-layout video.cdl-pe-none{
    pointer-events: none;
}

.common-digital-layout video.cdl-video2{
    border: 4px solid rgb(65, 61, 64);
    background-color: #121114;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    margin: 20px auto;
}


.ctl-white.common-text-layout .ctl-heading{
    font-size: 32px;
}

.ctl-white.common-text-layout .ctl-text{
    color: white;
    font-size: 20px;
}

@media screen and (max-width:768px) {
    .ctl-white.common-text-layout .ctl-heading{
        font-size: 28px;
    }
    
    .ctl-white.common-text-layout .ctl-text{
        color: white;
        font-size: 18px;
    }
}

@media screen and (max-width:576px) {
    .common-text-layout .ctl-heading,
    .ctl-white.common-text-layout .ctl-heading{
        font-size: 24px;
    }
    
    .common-text-layout .ctl-text,
    .ctl-white.common-text-layout .ctl-text{
        font-size: 16px;
    }
    
}

@media screen and (max-width:400px) {
    .common-text-layout .ctl-heading,
    .ctl-white.common-text-layout .ctl-heading{
        font-size: 20px;
    }
    
    .common-text-layout .ctl-text,
    .ctl-white.common-text-layout .ctl-text{
        font-size: 14px;
    }
}

/* --- Common text layout Css End --- */


/* --- Common Table Css Start --- */
.custom-table-responsive{
    overflow: hidden;
    overflow-x: auto;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    background-color: var(--color5);
}

.ctr-scrollbar::-webkit-scrollbar{
    width: 1px;
    height: 1px;
}

.ctr-scrollbar::-webkit-scrollbar-track{
    -webkit-border-radius: 50px;
            border-radius: 50px;
    background-color: var(--color7);
}

.ctr-scrollbar::-webkit-scrollbar-thumb{
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-border-radius: 50px;
            border-radius: 50px;
}



.custom-table{
    width: 100%;
}

.custom-table thead{
    background-image: -webkit-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -moz-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -o-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: linear-gradient(-45deg, var(--color7), var(--color6));
    color: white;
}

.custom-table thead th{
    padding: 5px 15px;
    font-weight: 400;
}

.custom-table tbody td{
    padding: 10px 15px;
    font-weight: 400;
    width: 100%;
}

.custom-table tbody tr{
    cursor: pointer;
    background-color: transparent;
    border-bottom: 1px solid rgb(63, 59, 69);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-table tbody tr:hover{
    background-color: var(--color1-300);
}

.custom-table tbody tr:last-child{
    border-bottom: none;
}

.ct-data-outer{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.ct-data-outer svg{
    width: 45px;
    height: 45px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    padding: 12px;
    margin-right: 10px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    background: var(--color1-300);
    border: 1px solid rgb(63, 59, 69);
}

.ct-data-bx .ct-amount,
.ct-data-bx .ct-lable{
    color: #f2f1f3;
    font-size: 14px;
    font-weight: 400;
}

.ct-data-bx .ct-amount-lable,
.ct-data-bx .ct-date{
    color: #BFBAC4;
    font-size: 14px;
}

.ct-data-bx .ct-amount,
.ct-data-bx .ct-amount-lable{
    text-align: right;
    white-space: nowrap;
}

.ct-data-bx.ct-added .ct-amount-lable,
.ct-data-bx.ct-less .ct-amount-lable{
    color: white;
}

.custom-table tbody tr.lost-game,
.ct-data-bx.ct-less .ct-amount{
    color: var(--color4);
}

.custom-table tbody tr.win-game,
.ct-data-bx.ct-added .ct-amount{
    color: var(--color3);
}
/* --- Common Table Css End --- */


/* --- Empty Box Css start --- */
.empty-div-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 20px 0;
    width: 100%;
    min-height: 220px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 30px 10px;
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
    -webkit-filter: invert(1);
            filter: invert(1);
}

.empty-div-bx .empty-heading {
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 24px;
    margin-top: 10px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    color: rgb(191, 186, 196);
    font-weight: 400;
    font-size: 16px;
    margin-top: 3px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-btn {
    color: white;
    padding: 6px 15px;
    background-color: #302d34;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    margin-top: 10px;
    cursor: pointer;
}

.empty-div-bx .empty-btn:hover {
    background-color: #44404a;
}
/* --- Empty Box Css End--- */


/* --- Bootstrep Modal Css Overaid --- */

.modal{
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog{
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
}

.modal-content.custom-content{
    background-color: var(--color1);
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
}

.custom-modal-header{
    position: -webkit-sticky;
    position: sticky;
    padding: 20px 15px 15px;
}

.custom-modal-header .cmh-lable{
    font-size: 20px;
    color: #f2f1f3;
    font-weight: 400;
    text-align: center;
}

.custom-modal-header .cmh-sub-lable{
    font-size: 16px;
    color: #f2f1f3;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
}


/* --- Lagal pages css start --- */
.lagle-heading{
    color: white;
    font-size: 32px;
    margin-bottom: 0px;
    font-weight: 600;
    text-align: center;
    margin-bottom: -10px;
    margin-top: 15px;
}

.lagle-sub-heading{
    color: rgb(191, 186, 196);
    font-size: 16px;
    text-align: center;
}

.legal-content .main-lable {
    margin-top: 15px;
    margin-bottom: -20px;
    font-weight: 500;
    color: white;
    font-size: 20px;
    text-transform: capitalize;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 400;
    color: #f2f1f3;
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.legal-ul li,
.legal-content p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: rgb(191, 186, 196);
    font-weight: 300;
    text-align: justify;
}

.legal-ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 20px;
    /* list-style-position: inside; */
}

.legal-ul.ul-dick {
    list-style-type: none;
}

.legal-ul li a,
.legal-content p a {
    text-decoration: underline;
}

.legal-ul li a:hover,
.legal-content p a:hover {
    color: #d2ae6d;
}

@media screen and (max-width:576px) {
    .lagle-heading{
        font-size: 28px;
    }
}
/* --- Lagal pages css end --- */


/* --- Faqs page css --- */
.faqs-accordion{
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--color5);
}

.faqs-accordion .acc-item{
    position: relative;
    padding: 1px 0;
    border-bottom: 1px solid rgb(63, 59, 69);
}

.faqs-accordion .acc-item:last-child {
    border-bottom: none;
}

.faqs-accordion .acc-item .acc-heding{
    color: white;
    background: transparent;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 15px 0px;
    padding-right: 30px;
}

.faqs-accordion .acc-item .acc-heding.active {
    opacity: 0.7;
    color: white;
}

.faqs-accordion .acc-item .acc-heding.active:hover {
    opacity: 1;
    color: white;
}

.faqs-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 48%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 25px;
    height: 25px;
    font-size: 20px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.faqs-accordion .acc-item .acc-heding.active span.down-icon {
    -webkit-transform: translateY(-50%) rotate(180deg);
       -moz-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
         -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}

.faqs-accordion .acc-item .faqs-data{
    color: rgb(191, 186, 196);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    line-height: 1.6;
    text-align: justify;
}


.faqs-accordion .acc-item .faqs-data a {
    text-decoration: underline;
}

.faqs-accordion .acc-item .faqs-data a:hover {
    color: #d2ae6d;
}

.faqs-accordion .acc-item .acc-data.active{
    opacity: 0;
    visibility: collapse;
    display: none;
}

.faqs-accordion .acc-item .acc-data{
    opacity: 1;
    visibility: visible;
    display: block;
    -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

}
/* --- Faqs page css end --- */


/* --- Contact Us page --- */
.contact-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 10px;
    margin: 10px 0 0;
    cursor: pointer;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info:hover {
    border-color: var(--color2);
    background-color: var(--color2-100);
}

.contact-info .contact-icon {
    padding: 2px;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: invert(1);
            filter: invert(1);
    opacity: 0.5;
}

.contact-info:hover .contact-icon {
    opacity: 1;
}

.contact-info .contact-icon img {
    width: 100%;
}

.contact-text-lable,
.contact-info .contact-text h6 {
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.73;
    overflow: hidden;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info .contact-text p {
    color: rgb(191, 186, 196);
    font-size: 14px;
    margin: 0;
}

.contactform{
    padding: 20px 20px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    background-color: var(--color5);
}



/* --- Sitemap Page Css --- */
.sitemaplinks-bx {
    padding: 10px 10px 10px 10px;
    position: relative;
    margin-bottom: 40px;
}

.sitemap-heding {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    color: rgb(255, 255, 255);
    display: block;
    padding-bottom: 10px;
}

.sitemap-heding::before,
.sitemap-heding::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    background-color: rgb(63, 59, 69);
}

.sitemap-heding::before {
    left: inherit;
    right: 0;
    top: 100%;
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
    background-color: rgb(63, 59, 69);
}

.sitemap-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sitemap-ul li {
    width: 19%;
    display: inline-block;
    margin: 4px 0;
}

.sitemap-ul li .sitemap-link {
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 30px;
    font-size: 16px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
    color: rgb(191, 186, 196);
    font-weight: 400;
}

.sitemap-ul li .sitemap-link svg {
    margin-right: 3px;
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sitemap-ul li .sitemap-link:hover {
    color: white;
    text-decoration: underline;
}

.sitemap-ul li .sitemap-link:hover svg {
    color: white;
}

@media screen and (max-width:992px) {
    .sitemap-ul li {
        width: 24%;
    }
}

@media screen and (max-width:768px) {
    .sitemap-ul li {
        width: 32%;
    }
}

@media screen and (max-width:576px) {
    .sitemap-ul li {
        width: 49%;
    }
}
/* --- Sitemap page css end --- */