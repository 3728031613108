/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

.cmp-btn{
    position: fixed;
    bottom: 15px;
    left: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 46px;
    height: 46px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 46px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 46px;
            flex: 0 0 46px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    font-weight: 500;
    color: #f2f1f3;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--color3);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    /* display: none; */
}

/* ---
Flash Message Popup Start
--- */

.flash-message-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 10px 10px 50px;
    opacity: 0;
    visibility: hidden;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    background: #0000004d;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.flash-message-popup.fmp-active {
    opacity: 1;
    visibility: visible;
}

.flash-message {
    background-color: white;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    padding: 20px 15px;
    max-width: 450px;
    width: 95%;
    position: relative;
    position: fixed;
    left: 50%;
    bottom: 50px;
    -webkit-transform: translate(-50%, 20px);
       -moz-transform: translate(-50%, 20px);
        -ms-transform: translate(-50%, 20px);
         -o-transform: translate(-50%, 20px);
            transform: translate(-50%, 20px);
    opacity: 0;
    visibility: hidden;
    z-index: 1101;
    -webkit-box-shadow: 0 0 15px #0000003d;
       -moz-box-shadow: 0 0 15px #0000003d;
            box-shadow: 0 0 15px #0000003d;
    -webkit-transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
    -moz-transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.flash-message-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.flash-message.fmp-message {
    -webkit-transform: translate(-50%, 0px);
       -moz-transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px);
         -o-transform: translate(-50%, 0px);
            transform: translate(-50%, 0px);
    opacity: 1;
    visibility: visible;
}

.flash-message .flash-img {
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px;
    margin-right: 10px;
    -webkit-align-self: self-start;
        -ms-flex-item-align: self-start;
                -ms-grid-row-align: self-start;
            align-self: self-start;
}

.flash-message.ef-msg .flash-text h6 {
    font-weight: 500;
    font-size: 20px;
    color: var(--color4);
    margin-bottom: 0px;
}

.flash-message.sf-msg .flash-text h6 {
    font-weight: 500;
    font-size: 20px;
    color: var(--color3);
    margin-bottom: 0px;
}

.flash-message .flash-text p {
    color: black;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
    margin: 0;
    margin-top: 3px;
}

.flash-message-two .msg-close,
.flash-message .msg-close {
    right: 5px;
    top: 5px;
    width: 35px;
    height: 35px;
    color: black;
}

.flash-message-two .msg-close{
    color: white;
    right: 0px;
    top: 0px;
}

.flash-message-two .msg-close:hover,
.flash-message .msg-close:hover {
    color: var(--color4);
    background-color: transparent;
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

.flash-message-two .msg-close svg,
.flash-message .msg-close svg {
    width: 100%;
    height: 100%;
}
/* ---
Flash Message Popup End
--- */


/* ---
Flash Message Two Popup Start
--- */
.flash-message-two{
    position: fixed;
    right: 10px;
    top: 70px;
    z-index: 1024;
    max-width: 300px;
    padding: 5px 5px 5px 10px;
    color: #f2f1f3;
    border: 2px solid var(--color2);
    background-color: var(--color1);
    -webkit-box-shadow: 0 0 25px -10px var(--color2);
       -moz-box-shadow: 0 0 25px -10px var(--color2);
            box-shadow: 0 0 25px -10px var(--color2);
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    font-size: 14px;
    padding-right: 35px;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(50px);
       -moz-transform: translateX(50px);
        -ms-transform: translateX(50px);
         -o-transform: translateX(50px);
            transform: translateX(50px);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.flash-message-two.active{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0px);
       -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
         -o-transform: translateX(0px);
            transform: translateX(0px);
}

.flash-message-two > *{
    color: white;
    font-size: 16px;
    text-decoration: underline;
}

.flash-message-two.fmt-red{
    border-color: var(--color4);
    background-color: rgb(57 9 0);
    -webkit-box-shadow: 0 0 25px -10px var(--color4);
       -moz-box-shadow: 0 0 25px -10px var(--color4);
            box-shadow: 0 0 25px -10px var(--color4);
}

.flash-message-two.fmt-green{
    border-color: var(--color3);
    background-color: rgb(0, 57, 17);
    -webkit-box-shadow: 0 0 25px -10px var(--color3);
       -moz-box-shadow: 0 0 25px -10px var(--color3);
            box-shadow: 0 0 25px -10px var(--color3);
}

@media screen and (max-width:576px) {
    .flash-message-two{
        top: 120px;
    }
}

@media screen and (max-width:500px) {
    .flash-message-two{
        max-width: 100%;
        width: -webkit-calc(100% - 20px);
        width: -moz-calc(100% - 20px);
        width: calc(100% - 20px);
        -webkit-transform: translateY(-10px);
           -moz-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
             -o-transform: translateY(-10px);
                transform: translateY(-10px);
    }

    .flash-message-two.active{
        -webkit-transform: translateY(0px);
           -moz-transform: translateY(0px);
            -ms-transform: translateY(0px);
             -o-transform: translateY(0px);
                transform: translateY(0px);
    }
}
/* ---
Flash Message Two Popup End
--- */