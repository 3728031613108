/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

/* --- Login Page Back img --- */
.login-page-back-effect{
    position: fixed !important;
    top: 0;
    left: 0;
    background-image: url("../img/loginbg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.login-page-back-effect::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.8;
    -webkit-backdrop-filter: blur(0.5px);
            backdrop-filter: blur(0.5px);
    background-color: rgb(0, 0, 0);
}

/* --- Signinup header --- */
.signinup-header{
    position: relative;
}

.signinup-header .back-btn{
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.signinup-logo{
    display: block;
    width: 160px;
    position: relative;
    z-index: 1;
    margin: 20px auto 30px;
}


/* ---
Screen heading
--- */
.screen-hed {
    color: #f2f1f3;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 10px;
    /* text-align: center; */
}

.screen-pera {
    color: rgb(191, 186, 196);
    font-weight: 400;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 15px;
    text-align: center;
}

.forget-link,
.signuptooglebtn,
.otp-sent-msg {
    color: #bfbac4;
    font-weight: 400;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 15px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.forget-link{
    float: right;
    margin: 0;
}

.signuptooglebtn{
    font-size: 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: -10px;
    margin-bottom: 15px;
}

.otp-sent-msg span{
    color: #f2f1f3;
}

.forget-link:hover,
.otp-sent-msg:hover span{
    color: white;
    text-decoration: underline;
}

.login-this,
.edit-this{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #f2f1f3;
    border: 1px solid rgb(63, 59, 69);
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    padding: 3px 10px 0px;
    margin-left: 8px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
    top: 5px;
}

.login-this{
    padding: 2px 10px 2px;
    top: 3px;
}

.login-this svg,
.edit-this svg{
    width: 18px;
    /* height: 18px; */
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    margin-right: 5px;
    position: relative;
    top: -2px;
}

.login-this svg{
    top: 0;
}

.login-this:hover,
.edit-this:hover{
    border-color: #ff942d;
    color: white;
    background-color: #ff942d;
}

/* --- Signinup Form Css --- */
.sign-in-up-bx {
    width: 100%;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px 10px;
    min-height: -webkit-calc(100vh);
    min-height: -moz-calc(100vh);
    min-height: calc(100vh);
}

/* ---
Seconde Screen
--- */
.signinup-screen {
    max-width: 450px;
    width: 100%;
    position: relative;
    margin: auto;
    padding: 15px;
    display: none;
}

.signinup-screen.active{
    display: block;
}

.signinup-screen.mw768ss{
    max-width: 700px;
}

/* --- Sign In Up Site Info Css --- */
.sign-in-up-bx .site-info {
    font-size: 12px;
    color: rgb(191, 186, 196);
    text-align: center;
    margin-top: 10px;
    font-weight: 400;
}

.sign-in-up-bx .site-info span {
    display: inline-block;
    width: 12px;
}

.sign-in-up-bx .site-info span.site-link{
    width: auto;
    cursor: pointer;
}

.sign-in-up-bx .site-info span.site-link,
.sign-in-up-bx .site-info a {
    display: inline-block;
    color: #f2f1f3;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sign-in-up-bx .site-info span.site-link:hover,
.sign-in-up-bx .site-info a:hover {
    text-decoration: underline;
}

/* --- Resend Otp Btn --- */
.resend-otp-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 100%;
    font-weight: 400;
    color: var(--color3);
    background-color: transparent;
    border: 1px solid  var(--color3);
    font-size: 16px;
    margin: 15px 0;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.5px;
}

.resend-otp-btn[disabled]{
    pointer-events: none;
    opacity: 0.5;
    color: #f2f1f3;
    background-color: rgba(32, 30, 35, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.resend-otp-btn{
    margin-bottom: 20px;
}

.resend-otp-btn:hover{
    background-color: var(--color3);
    border-color: var(--color3);
    color: white;
}



@media screen and (max-width:576px) {
    
    .sign-in-up-bx {
        min-height: -webkit-calc(100vh - 120px);
        min-height: -moz-calc(100vh - 120px);
        min-height: calc(100vh - 120px);
    }

    .signinup-screen{
        margin-top: 0;
        max-width: 576px;
        padding-top: 25px;
    }

    .signinup-group.otp-group input{
        width: 40px;
        height: 40px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 40px;
           -moz-box-flex: 0;
            -ms-flex: 0 0 40px;
                flex: 0 0 40px;
    }

    .screen-hed{
        font-size: 22px;
    }

}

@media screen and (max-width:450px) {
    .signinup-screen{
       padding: 15px 5px;
       padding-top: 25px;
    }

}

@media screen and (max-width:370px) {
    .edit-this{
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0;
        margin-top: 5px;
        margin-left: auto;
    }
}


.form-success-msg {
    display: block;
    padding: 10px;
    margin: 10px 0;
    color: #155724; /* Dark green color for success messages */
    background-color: #d4edda; /* Light green background for success messages */
    border: 1px solid #c3e6cb; /* Border color */
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px; /* Rounded corners */
  }