/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

/* --- Commun Form Css --- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.signinup-group {
    position: relative;
    margin: 0px 0 15px;
}

.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-weight: 400;
    display: block;
    color: white;
    border: 0;
    font-size: 16px;
    background-color: rgba(32, 30, 35, 0.5);
    border: 1px solid rgb(63, 59, 69);
    width: 100%;
    outline: none;
    padding: 8px 15px;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

/* .signinup-group select option:first-child {
    font-weight: bold;
    color: red;
    opacity: 0;
} */

.signinup-group.sgli select,
.signinup-group.sgli input{
    padding-left: 50px;
}

.signinup-group.sgri input,
.signinup-group.sgri select{
    padding-right: 40px;
}

/* .signinup-group input[type="password"] {
    padding: 10px 40px 10px 10px;
} */

.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}

.signinup-group .twl-lable{
    display: block;
    text-align: right;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 300;
    color: #bfbac4;
}

.signinup-group .group__label {
    font-size: 14px;
    color: #f2f1f3;
    font-weight: 400;
    margin: 0;
    margin-bottom: 2px;
    margin-left: 5px;
}

.signinup-group .group__label span{
    font-size: 12px;
    color: #bfbac4;
    margin-left: 5px;
}

.signinup-group.sgli .group_left_icon{
    position: absolute;
    left: 0px;
    top: 26px;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    pointer-events: none;
}

.signinup-group.sgli .group_left_icon::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: rgb(63, 59, 69);
}

.signinup-group.sgri .group_right_icon{
    position: absolute;
    right: 0px;
    top: 26px;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
}

.signinup-group.sgri .group_right_icon::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: rgb(63, 59, 69);
}

.group_right_icon .gri_password{
    width: 24px;
    height: 24px;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.group_right_icon.active .gri_password,
.group_right_icon:hover .gri_password{
    opacity: 1;
}

.signinup-group .down-icon{
    border-style: solid;
    border-color: white;
    border-width: 0px 2px 2px 0px;
    display: inline-block;
    padding: 4px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    right: 14px;
    top: 40px;
    pointer-events: none;
}

.signinup-group select:hover,
.signinup-group textarea:hover,
.signinup-group input:hover {
    border-color: white;
    -webkit-box-shadow: 0 0 1px 1px white;
       -moz-box-shadow: 0 0 1px 1px white;
            box-shadow: 0 0 1px 1px white;
}

.signinup-group textarea:not(:-moz-placeholder-shown), .signinup-group select:not(:-moz-placeholder-shown), .signinup-group input:not(:-moz-placeholder-shown) {
    border-color: var(--color2);
    -moz-box-shadow: 0 0 1px 1px var(--color2);
         box-shadow: 0 0 1px 1px var(--color2);
}

.signinup-group textarea:not(:-ms-input-placeholder), .signinup-group select:not(:-ms-input-placeholder), .signinup-group input:not(:-ms-input-placeholder) {
    border-color: var(--color2);
    box-shadow: 0 0 1px 1px var(--color2);
}

.signinup-group textarea:focus,
.signinup-group textarea:not(:placeholder-shown),
.signinup-group select:focus,
.signinup-group select:not(:placeholder-shown),
.signinup-group input:focus,
.signinup-group input:not(:placeholder-shown) {
    border-color: var(--color2);
    -webkit-box-shadow: 0 0 1px 1px var(--color2);
       -moz-box-shadow: 0 0 1px 1px var(--color2);
            box-shadow: 0 0 1px 1px var(--color2);
}

.signinup-group textarea:not(:-moz-placeholder-shown)~.group__label, .signinup-group select:not(:-moz-placeholder-shown)~.group__label, .signinup-group input:not(:-moz-placeholder-shown)~.group__label {
   color: white;
}

.signinup-group textarea:not(:-ms-input-placeholder)~.group__label, .signinup-group select:not(:-ms-input-placeholder)~.group__label, .signinup-group input:not(:-ms-input-placeholder)~.group__label {
   color: white;
}

.signinup-group textarea:not(:placeholder-shown)~.group__label,
.signinup-group textarea:focus ~ .group__label,
.signinup-group select:not(:placeholder-shown)~.group__label,
.signinup-group select:focus ~ .group__label,
.signinup-group input:not(:placeholder-shown)~.group__label,
.signinup-group input:focus~.group__label {
   color: white;
}

/* --- signinup-group-radio --- */
.signinup-group-radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.signinup-group-radio label {
    cursor: pointer;
    position: relative;
    color: #f2f1f3;
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0;
    margin-right: 8px;
    padding: 5px 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    text-transform: capitalize;
    background-color: rgba(32, 30, 35, 0.5);
    border: 1px solid rgb(63, 59, 69);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.signinup-group-radio label:hover {
    color: white;
    border-color: white;
}

.signinup-group-radio input:checked + label {
    border-color: var(--color2);
    background-color: var(--color2);
    color: white;
}

/* --- otp-group --- */
.signinup-group.otp-group{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 5px 0 20px;
}

.signinup-group.otp-group input{
    width: 45px;
    height: 45px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 45px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 45px;
            flex: 0 0 45px;
    margin: 0 5px;
    text-align: center;
    padding: 0;
}

/* --- Signinup group nots --- */
.signinup-group-nots{
    background: var(--color1-500);
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 20px;
}

.signinup-group-nots span {
    font-size: 13px;
    color: #f2f1f3;
    font-weight: 500;
}

.signinup-group-nots p {
    color: #bfbac4;
    font-size: 14px;
    margin: 2px 0 5px;
    line-height: 1.7;
}

.signinup-group-nots p > * {
    color: white;
    text-decoration: underline;
    margin: 0 3px;
}

.signinup-group-nots p > a {
    text-decoration: underline;
}

.signinup-group-nots a{
    display: inline-block;
    color: #bfbac4;
    font-weight: 500;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: underline;
}

.signinup-group-nots a:hover{
    color: white;
    text-decoration: underline;
}

.signinup-group-nots a svg{
    width: 18px;
    height: 18px;
    margin-right: 8px;
}

/* --- clear-input-icon --- */
.clear-input-icon{
    position: absolute;
    right: -2px;
    top: 26px;
    width: 45px;
    height: 45px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    padding: 8px;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}

.clear-input-icon:hover{
    color: var(--color4);
}

.clear-input-icon svg{
    width: 100%;
    height: 100%;
}

/* ---
Error Input color css
--- */
.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: var(--color4);
    -webkit-box-shadow: 0 0 1px 1px var(--color4);
       -moz-box-shadow: 0 0 1px 1px var(--color4);
            box-shadow: 0 0 1px 1px var(--color4);
}

.signinup-group.error-group .group__label{
    color: var(--color4) !important;
}

/* Form Error Msg */
.form-error-msg{
    display: block;
    text-align: right;
    color: var(--color4);
    font-size: 14px;
    margin-bottom: -5px;
    margin-top: 5px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
}

/* --- Remember Input Checkbox --- */
.signinup-group-checkmark{
    margin: 5px 0 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.signinup-group-checkmark label{
    font-size: 14px;
    color: #f2f1f3;
    font-weight: 400;
    margin: 0;
    margin-left: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
}

.signinup-group-checkmark label .checkmark-icon{
    position: relative;
    top: -0.7px;
    width: 20px;
    height: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    margin-right: 8px;
    background-color: transparent;
    background-position: center;
    -webkit-box-shadow: 0 0 0px 4px rgb(63 59 69 / 0%) inset;
       -moz-box-shadow: 0 0 0px 4px rgb(63 59 69 / 0%) inset;
            box-shadow: 0 0 0px 4px rgb(63 59 69 / 0%) inset;
    border: 2px solid rgb(63, 59, 69);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--color2);
}

.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark input:checked + label .checkmark-icon {
    background-image: url(../img/icons/check.svg);
    border-color: var(--color2);
    background-color: var(--color2);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: 87%  90%;
       -moz-background-size: 87%  90%;
         -o-background-size: 87%  90%;
            background-size: 87%  90%;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}


/* --- Common File Upload Css --- */
.fileupload{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px 10px;
    width: 100%;
    min-height: 100px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    background-color: rgba(32, 30, 35, 0.5);
    border: 1px solid rgb(63, 59, 69);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.fileupload .feud-img{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable{
    color: rgb(191, 186, 196);
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable span{
    color: rgb(255, 255, 255);
    text-decoration: underline;
}

.fileupload:hover{
    border-style: solid;
    border-color: var(--color2);
    -webkit-box-shadow: 0 0 1px 1px var(--color2);
       -moz-box-shadow: 0 0 1px 1px var(--color2);
            box-shadow: 0 0 1px 1px var(--color2);
}

.fileupload:hover .feud-img{
    opacity: 1;
}

.fileupload:hover .feud-lable{
    color: white;
}

/* --- File Upload List --- */
.dataupload-list,
.fileupload-list{
    display: block;
    padding: 0;
}

.dataupload-list .dataupload-item,
.fileupload-list .fileupload-item{
    position: relative;
    padding: 12px;
    font-size: 16px;
    margin-top: 15px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    -webkit-box-shadow: 0 0 10px #00000070;
       -moz-box-shadow: 0 0 10px #00000070;
            box-shadow: 0 0 10px #00000070;
    background-color: rgba(32, 30, 35, 0.5);
    border: 1px solid rgb(63, 59, 69);
    list-style-position: inside;
    padding-right: 100px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

.fileupload-list .fileupload-item img{
    max-width: 150px;
    width: 100%;
    height: 90px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    background: #121114;
    -webkit-box-shadow: 0 0 10px 0px #00000070 inset;
       -moz-box-shadow: 0 0 10px 0px #00000070 inset;
            box-shadow: 0 0 10px 0px #00000070 inset;
}

.fileupload-list .fileupload-item.filstn{
    list-style: none;
}

.dataupload-list .dataupload-item{
    list-style: none;
    padding-right: 40px;
    padding: 6px 12px;
}

.dataupload-list .dataupload-item .dui-item{
    font-size: 17px;
}

.dataupload-list .dataupload-item .dui-item span{
    color: rgb(129, 122, 138);
    font-size: 14px;
    margin-left: 2px;
    font-weight: 500;
}

.fileupload-list .fileupload-item .fileupload-play,
.dataupload-list .dataupload-item .clear-input-icon,
.fileupload-list .fileupload-item .clear-input-icon{
    top: 4px;
}

.fileupload-list .fileupload-item .fileupload-play{
    right: 45px;
}

.fileupload-video-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.fileupload-video-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.video-wraper-item{
    position: relative;
    width: 100%;
    max-width: 992px;
    height: 100%;
}

.video-wraper-item video{
    position: absolute;
    max-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    max-height: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
}


/* --- Input Autofill Css --- */
.signinup-group input:-webkit-autofill,
.signinup-group input:-webkit-autofill:hover,
.signinup-group input:-webkit-autofill:focus,
.signinup-group input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 16px !important;
    caret-color: white !important;
    font-family: 'Cerebri', sans-serif !important;
    -webkit-text-fill-color: white !important;
    box-shadow: 0 0 1px 1px var(--color2), 0 0 0 100px rgb(32, 30, 35) inset !important;
    -webkit-box-shadow: 0 0 1px 1px var(--color2), 0 0 0 100px rgb(32, 30, 35) inset !important;
}


/* --- login & submit btn --- */
.common-submit-btn,
.lsb-loader-btn,
.login-submit-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 100%;
    font-weight: 400;
    color: #f2f1f3;
    font-size: 18px;
    margin: 15px auto;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--color2);
    -webkit-transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-transform 0.3s ease;
    -o-transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, -o-transform 0.3s ease;
    -moz-transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease, -moz-transform 0.3s ease;
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease;
    transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease, -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease;
    max-width: 450px;
    width: 100%;
    letter-spacing: 0.5px;
}

.common-submit-btn[disabled],
.login-submit-btn[disabled]{
    opacity: 0.7; 
}

.common-submit-btn:hover,
.login-submit-btn:hover{
    color: white;
}

.common-submit-btn:active,
.login-submit-btn:active{
    -webkit-transform: scale(0.95);
       -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
         -o-transform: scale(0.95);
            transform: scale(0.95);
}


/* --- lsb loader btn --- */
.lsb-loader-btn img{
    pointer-events: none;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    -webkit-animation: rotation-btn 1s infinite linear;
       -moz-animation: rotation-btn 1s infinite linear;
         -o-animation: rotation-btn 1s infinite linear;
            animation: rotation-btn 1s infinite linear;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


/* --- White Form --- */

.form-white .signinup-group select,
.form-white .signinup-group textarea,
.form-white .signinup-group input {
    font-weight: 500;
    color: black;
    background-color: white;
}

.form-white .signinup-group select:hover,
.form-white .signinup-group textarea:hover,
.form-white .signinup-group input:hover {
    border-color: var(--color2);
    -webkit-box-shadow: 0 0 1px 1px var(--color2);
       -moz-box-shadow: 0 0 1px 1px var(--color2);
            box-shadow: 0 0 1px 1px var(--color2);
}

.form-white .signinup-group select::-webkit-input-placeholder, .form-white .signinup-group textarea::-webkit-input-placeholder, .form-white .signinup-group input::-webkit-input-placeholder{
    font-weight: 400;
    color: gray;
}

.form-white .signinup-group select:-moz-placeholder, .form-white .signinup-group textarea:-moz-placeholder, .form-white .signinup-group input:-moz-placeholder{
    font-weight: 400;
    color: gray;
}

.form-white .signinup-group select::-moz-placeholder, .form-white .signinup-group textarea::-moz-placeholder, .form-white .signinup-group input::-moz-placeholder{
    font-weight: 400;
    color: gray;
}

.form-white .signinup-group select:-ms-input-placeholder, .form-white .signinup-group textarea:-ms-input-placeholder, .form-white .signinup-group input:-ms-input-placeholder{
    font-weight: 400;
    color: gray;
}

.form-white .signinup-group select::-ms-input-placeholder, .form-white .signinup-group textarea::-ms-input-placeholder, .form-white .signinup-group input::-ms-input-placeholder{
    font-weight: 400;
    color: gray;
}

.form-white .signinup-group select::placeholder,
.form-white .signinup-group textarea::placeholder,
.form-white .signinup-group input::placeholder{
    font-weight: 400;
    color: gray;
}

.form-white .clear-input-icon{
    top: 0px;
    color: #e32600;    
}

@media (max-width:576px) {
    .signinup-group-nots{
        background: var(--color2-200);
    }
}