/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

.main-header{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 10px;
    background-color: var(--color5);
    z-index: 1024;
    -webkit-box-shadow: 0 0 45px -15px black;
       -moz-box-shadow: 0 0 45px -15px black;
            box-shadow: 0 0 45px -15px black;
}

.main-header .mh-inner{
    height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}


/* --- Logo Css Start --- */
.main-header .mh-inner .mh-left .logo-links{
    display: block;
    width: 120px;
    height: 46px;
}

.main-header .mh-inner .mh-left .logo-links img{
    width: 100%;
    height: 100%;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
}
/* --- Logo css end --- */


/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 25px;
    height: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25px;
            flex: 0 0 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
    margin-left: 5px;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 3px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.7;
    background-color: white;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- Signinup Btn Bx Start --- */
.signinup-btn-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-left: 5px;
    /* padding-right: 5px; */
}

.sip-wallet-btn,
.signinup-btn-bx .sip-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px 12px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    font-size: 14px;
    margin: 0 3px;
    background-color: var(--color1-300);
    border: 1px solid rgb(63, 59, 69);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
}

.sip-wallet-btn svg,
.signinup-btn-bx .sip-btn svg{
    width: 20px;
    height: 20px;
    position: relative;
    margin-right: 5px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}

.sip-wallet-btn img{
    width: 20px;
    height: 20px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    position: relative;
    top: -0.8px;
}

.sip-wallet-btn:hover,
.signinup-btn-bx .sip-btn:hover{
    background-color: white;
    color: var(--color1);
    cursor: pointer;
}

/* .sip-wallet-btn{
    position: relative;
}

.sip-wallet-btn .sip-wallet-notis{
    white-space: nowrap;
    pointer-events: none;
    position: absolute;
    top: calc(100% + 15px);
    background-color: var(--color1-300);
    border: 1px solid rgb(63, 59, 69);
    padding: 2px 10px;
    border-radius: 50px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.sip-wallet-btn .sip-wallet-notis{
    opacity: 1;
    visibility: visible;
}

.sip-wallet-btn .sip-wallet-notis::after{
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, -8px) rotate(45deg);
    width: 10px;
    height: 10px;
}

.sip-wallet-btn .sip-wallet-notis.added-wn::after,
.sip-wallet-btn .sip-wallet-notis.added-wn{
    background-color: var(--color3);
    border: 1px solid var(--color3);
    color: white;
}

.sip-wallet-btn .sip-wallet-notis.less-wn::after,
.sip-wallet-btn .sip-wallet-notis.less-wn{
    background-color: var(--color4);
    border: 1px solid var(--color4);
    color: white;
} */

/* --- Signinup Btn Bx End --- */


@media (max-width:576px) {
    .main-header{
        padding-top: 60px;
    }

    .signinup-btn-bx{
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        padding: 8px;
        border-bottom: 1px solid rgb(255 255 255 / 10%);
    }
}

@media (max-width:450px) {

    .signinup-btn-bx{
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .signinup-btn-bx .sip-btn{
        width: 35px;
        height: 35px;
        padding: 0;
    } 

    .signinup-btn-bx .sip-btn span{
        display: none;
    } 

    .signinup-btn-bx .sip-btn svg{
        margin: 0;
    } 
}