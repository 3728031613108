/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 120 version
*/

.gamesreen-container{
    max-width: 1350px;
    width: 100%;
    margin: auto;
    padding: 0 12px;
}

.gamesreen-main-bx{
    padding: 0 12px;
}

.gamesreen-common-bx,
.gamesreen-bx{
    -webkit-border-radius: 15px;
       -moz-border-radius: 15px;
            border-radius: 15px;
    padding: 20px;
    background-color: var(--color5);
}

.gamesreen-bx .gamesreen-inner{
    position: relative;
    height: 230px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

/* --- gamesreen mountains --- */
.gi-mountains-bx{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.gi-mountains-bx.active{
    opacity: 0;
}

.gi-mountains-bx .gi-mountain{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    background-repeat: repeat-x;
    background-position: center;
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    -webkit-transition: background-position .3s ease-in-out;
    -o-transition: background-position .3s ease-in-out;
    -moz-transition: background-position .3s ease-in-out;
    transition: background-position .3s ease-in-out;
    /* animation: mountain-move 60s paused linear infinite; */
}

.gi-mountains-bx .gi-mountain.gim-1{
    height: 30px;
    background-image: url("../img/aitplane/mountain-(1).svg");
}

.gi-mountains-bx .gi-mountain.gim-2{
    height: 60px;
    /* animation-duration: 90s; */
    background-image: url("../img/aitplane/mountain-(2).svg");
}

.gi-mountains-bx .gi-mountain.gim-3{
    height: 90px;
    /* animation-duration: 150s; */
    background-image: url("../img/aitplane/mountain-(3).svg");
}

.gi-mountains-bx .gi-mountain.gim-4{
    height: 120px;
    /* animation-duration: 210s; */
    background-image: url("../img/aitplane/mountain-(4).svg");
}

.gi-mountains-bx .gi-mountain.gim-5{
    height: 160px;
    /* animation-duration: 220s; */
    background-image: url("../img/aitplane/mountain-(5).svg");
}
  
@-webkit-keyframes mountain-move{
    to{background-position-x: -500em;}
}
  
@-moz-keyframes mountain-move{
    to{background-position-x: -500em;}
}
  
@-o-keyframes mountain-move{
    to{background-position-x: -500em;}
}
  
@keyframes mountain-move{
    to{background-position-x: -500em;}
}

/* --- gamesreen Dots --- */
.dots{
    position: absolute;   
    -webkit-background-size: contain;   
       -moz-background-size: contain;   
         -o-background-size: contain;   
            background-size: contain;
    -webkit-transition: background-position .3s ease-in-out;
    -o-transition: background-position .3s ease-in-out;
    -moz-transition: background-position .3s ease-in-out;
    transition: background-position .3s ease-in-out;
}

.dots-x{
    width: 100%;
    height: 5px;
    left: 0px;
    bottom: -10px;
    background-image: url("../img/icons/dot-x.svg");
    /* animation: dots-move-x 40s paused linear infinite; */
}

.dots-y{
    width: 5px;
    height: 100%;
    top: 0px;
    left: -10px;
    background-image: url("../img/icons/dot-y.svg");
    /* animation: dots-move-y 40s paused linear infinite; */
}


@-webkit-keyframes dots-move-x {
    to{background-position-x: -500em;}
}


@-moz-keyframes dots-move-x {
    to{background-position-x: -500em;}
}


@-o-keyframes dots-move-x {
    to{background-position-x: -500em;}
}


@keyframes dots-move-x {
    to{background-position-x: -500em;}
}

@-webkit-keyframes dots-move-y {
    to{background-position-y: 500em;}
}

@-moz-keyframes dots-move-y {
    to{background-position-y: 500em;}
}

@-o-keyframes dots-move-y {
    to{background-position-y: 500em;}
}

@keyframes dots-move-y {
    to{background-position-y: 500em;}
}


/* --- gamesreen airplane & crash --- */
.gi-airplane-bx{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 10px;
    height: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    /* animation: airplane-move 5s linear paused forwards; */
    z-index: 1;
}

.gi-airplane-bx::after{
    content: " ";
    position: absolute;
    right: -3px;
    top: -3px;
    width: 10px;
    height: 10px;
    background-color: var(--color6);
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: -1;
    display: none;
}

.gi-airplane-bx > img{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
}

@-webkit-keyframes airplane-move {
    to{
        width: -webkit-calc(100% - 50px);
        width: calc(100% - 50px);
        height: -webkit-calc(100% - 25px);
        height: calc(100% - 25px);
    }
}

@-moz-keyframes airplane-move {
    to{
        width: -moz-calc(100% - 50px);
        width: calc(100% - 50px);
        height: -moz-calc(100% - 25px);
        height: calc(100% - 25px);
    }
}

@-o-keyframes airplane-move {
    to{
        width: calc(100% - 50px);
        height: calc(100% - 25px);
    }
}

@keyframes airplane-move {
    to{
        width: -webkit-calc(100% - 50px);
        width: -moz-calc(100% - 50px);
        width: calc(100% - 50px);
        height: -webkit-calc(100% - 25px);
        height: -moz-calc(100% - 25px);
        height: calc(100% - 25px);
    }
}

.gi-img-item{
    width: 76px;
    height: 50px;
    position: absolute;
    right: -30px;
    top: -20px;
    background-position: left center;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    display: none;
} 

.gi-img-item.gi-airplane{
    -webkit-transform: rotate(-25deg);
       -moz-transform: rotate(-25deg);
        -ms-transform: rotate(-25deg);
         -o-transform: rotate(-25deg);
            transform: rotate(-25deg);
    background-image: url("../img/aitplane/airplane.png");
    -webkit-animation: steps-sprite steps(47, start) 3s infinite;
       -moz-animation: steps-sprite steps(47, start) 3s infinite;
         -o-animation: steps-sprite steps(47, start) 3s infinite;
            animation: steps-sprite steps(47, start) 3s infinite;
}

@-webkit-keyframes steps-sprite{
    to{background-position-x:100%}
}

@-moz-keyframes steps-sprite{
    to{background-position-x:100%}
}

@-o-keyframes steps-sprite{
    to{background-position-x:100%}
}

@keyframes steps-sprite{
    to{background-position-x:100%}
}

.gi-img-item.crash-img{
    height: 76px;
    width: 76px;
    right: -40px;
    top: -30px;
    background-image: url("../img/aitplane/aitplane-crash.png");
    -webkit-animation:steps-crash-sprite steps(24, start) 2s 1 forwards;
       -moz-animation:steps-crash-sprite steps(24, start) 2s 1 forwards;
         -o-animation:steps-crash-sprite steps(24, start) 2s 1 forwards;
            animation:steps-crash-sprite steps(24, start) 2s 1 forwards;
    display: none;
}

@-webkit-keyframes steps-crash-sprite{
    to{background-position-x:100%}
}

@-moz-keyframes steps-crash-sprite{
    to{background-position-x:100%}
}

@-o-keyframes steps-crash-sprite{
    to{background-position-x:100%}
}

@keyframes steps-crash-sprite{
    to{background-position-x:100%}
}


/* --- gamesreen countdown --- */
.game-end .gi-countdown-bx,
.game-start .gi-countdown-bx{
    opacity: 0;
    visibility: hidden;
}

.gi-countdown-bx{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;
    height: 150px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    z-index: 2;
}

.gi-countdown-bx .gi-countdown{
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    bottom: 5px;
}

.gi-countdown-bx .gi-step-indicator{
    position: absolute;
    bottom: 25px;
    right: -75px;
    width: 100%;
    -webkit-animation: circle-bounce 1s .7s linear infinite;
       -moz-animation: circle-bounce 1s .7s linear infinite;
         -o-animation: circle-bounce 1s .7s linear infinite;
            animation: circle-bounce 1s .7s linear infinite;
}

.gi-countdown-bx .gi-step-indicator::after{
    display: block;
    content: " ";
    position: relative;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: white;
}

@-webkit-keyframes circle-bounce{
    70%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}
    
    80%{-webkit-transform:rotate(-11deg);transform:rotate(-11deg)}
    
    90%{-webkit-transform:rotate(-2deg);transform:rotate(-2deg)}
    
    0%,40%,100%{-webkit-transform:rotate(-7deg);transform:rotate(-7deg)}
}

@-moz-keyframes circle-bounce{
    70%{-moz-transform:rotate(0deg);transform:rotate(0deg)}
    
    80%{-moz-transform:rotate(-11deg);transform:rotate(-11deg)}
    
    90%{-moz-transform:rotate(-2deg);transform:rotate(-2deg)}
    
    0%,40%,100%{-moz-transform:rotate(-7deg);transform:rotate(-7deg)}
}

@-o-keyframes circle-bounce{
    70%{-o-transform:rotate(0deg);transform:rotate(0deg)}
    
    80%{-o-transform:rotate(-11deg);transform:rotate(-11deg)}
    
    90%{-o-transform:rotate(-2deg);transform:rotate(-2deg)}
    
    0%,40%,100%{-o-transform:rotate(-7deg);transform:rotate(-7deg)}
}

@keyframes circle-bounce{
    70%{-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg)}
    
    80%{-webkit-transform:rotate(-11deg);-moz-transform:rotate(-11deg);-o-transform:rotate(-11deg);transform:rotate(-11deg)}
    
    90%{-webkit-transform:rotate(-2deg);-moz-transform:rotate(-2deg);-o-transform:rotate(-2deg);transform:rotate(-2deg)}
    
    0%,40%,100%{-webkit-transform:rotate(-7deg);-moz-transform:rotate(-7deg);-o-transform:rotate(-7deg);transform:rotate(-7deg)}
}

.gi-countdown-bx .gi-step-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    right: -45px;
    bottom: -30px;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-30deg);
       -moz-transform: rotate(-30deg);
        -ms-transform: rotate(-30deg);
         -o-transform: rotate(-30deg);
            transform: rotate(-30deg);
    -webkit-transition: -webkit-transform 0.7s ease-in-out;
    transition: -webkit-transform 0.7s ease-in-out;
    -o-transition: -o-transform 0.7s ease-in-out;
    -moz-transition: transform 0.7s ease-in-out, -moz-transform 0.7s ease-in-out;
    transition: transform 0.7s ease-in-out;
    transition: transform 0.7s ease-in-out, -webkit-transform 0.7s ease-in-out, -moz-transform 0.7s ease-in-out, -o-transform 0.7s ease-in-out;
}


.gi-countdown-bx .gi-step-bx.hidesomestpe .gi-step-item:nth-child(4),
.gi-countdown-bx .gi-step-bx.hidesomestpe .gi-step-item:nth-child(3),
.gi-countdown-bx .gi-step-bx.hidesomestpe .gi-step-item:nth-child(2),
.gi-countdown-bx .gi-step-bx.hidesomestpe .gi-step-item:nth-child(1){
    opacity: 0;
}

.gi-countdown-bx .gi-step-bx .gi-step-item{
    position: absolute;
    width: 100%;
}

.gi-countdown-bx .gi-step-bx .gi-step-item::after{
    display: block;
    content: " ";
    position: relative;
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    width: 8px;
    height: 5px;
    background-color: white;
}

.gi-countdown-bx .gi-step-bx .gi-step-item.lastgsi:after{
    background-color: var(--color6);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(1){
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(2){
    -webkit-transform: rotate(30deg);
       -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
         -o-transform: rotate(30deg);
            transform: rotate(30deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(3){
    -webkit-transform: rotate(60deg);
       -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
         -o-transform: rotate(60deg);
            transform: rotate(60deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(4){
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(5){
    -webkit-transform: rotate(120deg);
       -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
         -o-transform: rotate(120deg);
            transform: rotate(120deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(6){
    -webkit-transform: rotate(150deg);
       -moz-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
         -o-transform: rotate(150deg);
            transform: rotate(150deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(7){
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(8){
    -webkit-transform: rotate(210deg);
       -moz-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
         -o-transform: rotate(210deg);
            transform: rotate(210deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(9){
    -webkit-transform: rotate(240deg);
       -moz-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
         -o-transform: rotate(240deg);
            transform: rotate(240deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(10){
    -webkit-transform: rotate(270deg);
       -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
         -o-transform: rotate(270deg);
            transform: rotate(270deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(11){
    -webkit-transform: rotate(300deg);
       -moz-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
         -o-transform: rotate(300deg);
            transform: rotate(300deg);
}

.gi-countdown-bx .gi-step-bx .gi-step-item:nth-child(12){
    -webkit-transform: rotate(330deg);
       -moz-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
         -o-transform: rotate(330deg);
            transform: rotate(330deg);
}


/* --- Gamesreen Win Indicator --- */
.gi-win-indicator{
    font-size: 36px;
    font-weight: 600;
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #f2f1f3;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.gi-win-indicator.active{
    opacity: 1;
    visibility: visible;
}


/* --- Gamesreen Waiting --- */
.gi-waiting{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: -webkit-calc(100% + 40px);
    width: -moz-calc(100% + 40px);
    width: calc(100% + 40px);
    height: -webkit-calc(100% + 40px);
    height: -moz-calc(100% + 40px);
    height: calc(100% + 40px);
    left: -20px;
    bottom: -20px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-border-radius: 15px;
       -moz-border-radius: 15px;
            border-radius: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.gi-waiting.active{
    opacity: 1;
    visibility: visible;
}

.gi-waiting .gi-waiting-img{
    margin-top: -30px;
    position: relative;
    width: 150px;
    height: 150px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.gi-waiting .gi-waiting-img .gi-wi-after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/aitplane/airplane-shine.png");
    background-position: center;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5;
    -webkit-animation: gi-wi-after-rotate 15s infinite;
       -moz-animation: gi-wi-after-rotate 15s infinite;
         -o-animation: gi-wi-after-rotate 15s infinite;
            animation: gi-wi-after-rotate 15s infinite;
}

@-webkit-keyframes gi-wi-after-rotate{
    to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes gi-wi-after-rotate{
    to{
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes gi-wi-after-rotate{
    to{
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes gi-wi-after-rotate{
    to{
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.gi-waiting .gi-waiting-img .gi-wi-airplane{
    position: relative;
    z-index: 1;
    width: 76px;
    height: 50px;
    background-position: left center;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../img/aitplane/airplane.png");
    -webkit-animation: steps-sprite steps(47, start) 3s infinite;
       -moz-animation: steps-sprite steps(47, start) 3s infinite;
         -o-animation: steps-sprite steps(47, start) 3s infinite;
            animation: steps-sprite steps(47, start) 3s infinite;
}

.gi-waiting .gi-wi-text{
    margin-top: -40px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}
    

/* --- Game start --- */
.game-start .gi-airplane-bx{
    -webkit-animation: airplane-move 5s linear paused forwards;
       -moz-animation: airplane-move 5s linear paused forwards;
         -o-animation: airplane-move 5s linear paused forwards;
            animation: airplane-move 5s linear paused forwards;
}

.game-start .gi-mountains-bx .gi-mountain{
    -webkit-animation: mountain-move 60s paused linear infinite;
       -moz-animation: mountain-move 60s paused linear infinite;
         -o-animation: mountain-move 60s paused linear infinite;
            animation: mountain-move 60s paused linear infinite;
}

.game-start .gi-mountains-bx .gi-mountain.gim-2{
    -webkit-animation-duration: 90s;
       -moz-animation-duration: 90s;
         -o-animation-duration: 90s;
            animation-duration: 90s;
}

.game-start .gi-mountains-bx .gi-mountain.gim-3{
    -webkit-animation-duration: 150s;
       -moz-animation-duration: 150s;
         -o-animation-duration: 150s;
            animation-duration: 150s;
}

.game-start .gi-mountains-bx .gi-mountain.gim-4{
    -webkit-animation-duration: 210s;
       -moz-animation-duration: 210s;
         -o-animation-duration: 210s;
            animation-duration: 210s;
}

.game-start .gi-mountains-bx .gi-mountain.gim-5{
    -webkit-animation-duration: 250s;
       -moz-animation-duration: 250s;
         -o-animation-duration: 250s;
            animation-duration: 250s;
}

.game-start .dots-x{
    -webkit-animation: dots-move-x 60s paused linear infinite;
       -moz-animation: dots-move-x 60s paused linear infinite;
         -o-animation: dots-move-x 60s paused linear infinite;
            animation: dots-move-x 60s paused linear infinite;
}

.game-start .dots-y{
    -webkit-animation: dots-move-y 60s paused linear infinite;
       -moz-animation: dots-move-y 60s paused linear infinite;
         -o-animation: dots-move-y 60s paused linear infinite;
            animation: dots-move-y 60s paused linear infinite;
}


.game-start .gi-img-item.gi-airplane,
.game-start .gi-airplane-bx::after,
.game-start .gi-airplane-bx > img{
    display: block;
}

.game-start .gi-airplane-bx,
.game-start .gi-mountains-bx .gi-mountain,
.game-start .dots-x,
.game-start .dots-y{
    -webkit-animation-play-state: running;
       -moz-animation-play-state: running;
         -o-animation-play-state: running;
            animation-play-state: running;
}


/* --- Game End --- */
.game-end .gi-airplane-bx,
.game-end .dots-x,
.game-end .dots-y,
.game-end .gi-mountains-bx .gi-mountain{
    -webkit-animation-play-state: paused;
       -moz-animation-play-state: paused;
         -o-animation-play-state: paused;
            animation-play-state: paused;
}

.game-end .gi-airplane-bx > img,
.game-end .gi-airplane-bx::after,
.game-end .gi-img-item.gi-airplane{
    display: none;
}

.game-end .gi-img-item.crash-img{
    display: block;
}

@media (max-width:992px) {
    .gamesreen-main-bx{
        padding: 0px;
    }
    .gamesreen-bx{
        -webkit-border-radius: 0;
           -moz-border-radius: 0;
                border-radius: 0;
        padding-right: 0;
        padding-top: 0;
    }

    @-webkit-keyframes airplane-move {
        to{
            width: -webkit-calc(100% - 50px);
            width: calc(100% - 50px);
            height: -webkit-calc(100% - 50px);
            height: calc(100% - 50px);
        }
    }

    @-moz-keyframes airplane-move {
        to{
            width: -moz-calc(100% - 50px);
            width: calc(100% - 50px);
            height: -moz-calc(100% - 50px);
            height: calc(100% - 50px);
        }
    }

    @-o-keyframes airplane-move {
        to{
            width: calc(100% - 50px);
            height: calc(100% - 50px);
        }
    }

    @keyframes airplane-move {
        to{
            width: -webkit-calc(100% - 50px);
            width: -moz-calc(100% - 50px);
            width: calc(100% - 50px);
            height: -webkit-calc(100% - 50px);
            height: -moz-calc(100% - 50px);
            height: calc(100% - 50px);
        }
    }

    .gi-waiting{
        width: -webkit-calc(100% + 20px);
        width: -moz-calc(100% + 20px);
        width: calc(100% + 20px);
        height: -webkit-calc(100% + 20px);
        height: -moz-calc(100% + 20px);
        height: calc(100% + 20px);;
        -webkit-border-radius: 0px;;
           -moz-border-radius: 0px;;
                border-radius: 0px;
    }
}

@media (max-width:576px) {
    .gi-img-item.gi-airplane{
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }
}


/* --- Game top Btns Designs --- */
.gamesreen-top-buttons{
    position: relative;
    z-index: 11;
    padding: 10px 25px 0;
    margin-bottom: -36.8px;
}

.gamesreen-top-buttons .gs-tb-item{
    padding: 1px 15px 0px;
    color: var(--color6);
    border: 1px solid var(--color6);
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.gamesreen-top-buttons .gs-tb-item:hover{
    color: white;
    background-color: var(--color6);
}

@media screen and (max-width:992px) {
    .gamesreen-top-buttons{
        padding: 10px 10px 0;
    }
}

/* --- Game Btns Designs --- */

.gcb-heading{
    color: #f2f1f3;
    font-size: 14px;
    font-weight: 400;
    margin-top: -5px;
    margin-bottom: 10px;
}

.beting-btns-group{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: -5px;
    margin-bottom: -3px;
}

.beting-btns-group .beting-btn{
    color: white;
    padding: 3px 3px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 16.33%;
       -moz-box-flex: 0;
        -ms-flex: 0 1 16.33%;
            flex: 0 1 16.33%;
    width: 16.33%;
    text-align: center;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    font-size: 14px;
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
    margin: 0 0 8px 0;
    border: 1px solid var(--color2);
    background-color: var(--color2-300);
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media (min-width:992px) {
    .beting-btns-group .beting-btn:hover{
        border: 1px solid var(--color6);
        background-color: var(--color6);
    } 
}


.beting-btns-group .beting-btn:active{
    border: 1px solid var(--color6);
    background-color: var(--color6);
}

.beting-place_take-btns{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 5px;
}

.beting-place_take-btns button{
    width: 49%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 49%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 49%;
            flex: 0 0 49%;
    height: 40px;
    text-align: center;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: white;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    font-size: 14px;
    text-transform: capitalize;
    opacity: 0.8;
}

.beting-place_take-btns button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.beting-place_take-btns button:nth-child(1){
    border: 1px solid var(--color6);
    background-color: var(--color6);
    color: white;
}

.beting-place_take-btns button:nth-child(2){
    border: 1px solid var(--color3);
    background-color: var(--color3);
    color: white;
}

.beting-place_take-btns button.accepted:nth-child(1){
    border: 1px solid var(--color3);
    background-color: var(--color3);
    color: white;
}

/* .beting-place_take-btns button:not([disabled]):hover{
    opacity: 0.9;
} */

.beting-place_take-btns button:not([disabled]):active{
    opacity: 1;
    -webkit-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    -moz-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
}



/* @media (max-width:992px) {
    .beting-btns-group .beting-btn{
        flex: 0 1 16.33%;
        width: 16.33%;
    }
} */

/* @media (max-width:768px) {
    .beting-btns-group .beting-btn{
        flex: 0 1 20%;
        width: 20%;
    }
} */

@media (max-width:576px) {
    .gamesreen-common-bx{
          padding: 20px 10px;  
    }

    .beting-btns-group .beting-btn{
        -webkit-box-flex: 0;
        -webkit-flex: 0 1 25%;
           -moz-box-flex: 0;
            -ms-flex: 0 1 25%;
                flex: 0 1 25%;
        width: 25%;
    }
}


/* --- Gamesreen Table --- */

.gs-table-radius{
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    overflow: hidden;
}

.gs-table-heading-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background-image: -webkit-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -moz-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: -o-linear-gradient(135deg, var(--color7), var(--color6));
    background-image: linear-gradient(-45deg, var(--color7), var(--color6));
}

.gs-table-heading-bx .gs-table-heading-item{
    font-size: 14px;
    padding: 5px 10px;
}

.gs-table-heading-bx .gs-table-heading-item svg{
    width: 17px;
    height: 17px;
    position: relative;
    top: -1px;
}

.gs-player-table-heading-bx .gs-table-heading-item{
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}

.gs-player-table-heading-bx .gs-table-heading-item svg{
    width: 14px;
    height: 14px;
    position: relative;
    top: -1px;
    margin-right: 3px;
}

.gs-table-responsive{
    height: -webkit-calc(213.78px - 33.8px);
    height: -moz-calc(213.78px - 33.8px);
    height: calc(213.78px - 33.8px);
    overflow: auto;
    -webkit-border-radius: 0;
       -moz-border-radius: 0;
            border-radius: 0;
}

.gs-table-responsive.gs-players-table-height{
    height: -webkit-calc(461px);
    height: -moz-calc(461px);
    height: calc(461px);
}

.gs-table-responsive .custom-table thead{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-bottom: 1px solid rgb(63, 59, 69);
}

.gs-table-responsive .custom-table thead tr{
    background: #090e2a;
}

.gs-table-responsive .custom-table thead tr th{
    padding: 4px 10px;
}

.gs-table-responsive .custom-table tbody tr td,
.gs-table-responsive .custom-table thead tr th{
    font-size: 12px;
    width: auto;
    padding: 5px 5px;
    white-space: nowrap;
}

@media (max-width:992px) {
    .gs-table-responsive.gs-players-table-height{
        height: auto;
    }
}

@media (max-width:768px) {
    .gs-table-responsive{
        height: auto;
    }
}